import React from "react"
import { map } from "lodash"

export default () => {
  const footerSections = {
    Apps: {
      Retrollector: "https://retrollector.com",
      Machinepop: "https://machinepop.com",
    },
    Sections: {
      Apps: "/",
      "Blog & News": "/blog",
      "Open Source": "https://github.com/thefunbots",
      //Others: "/",
    },
    Company: {
      "About us": "/about",
      Contact: "/contact",
      //Support: "/support",
    },
  }

  const socialNetworks = [
    {
      name: "Twitter",
      url: "https://twitter.com/thefunbots",
      image: "/images/icons/twitter.svg",
    },
    {
      name: "Discord",
      url: "https://discord.com/thefunbots",
      image: "/images/icons/discord.svg",
    },
    {
      name: "Facebook",
      url: "https://facebook.com/thefunbots",
      image: "/images/icons/facebook.svg",
    },
    {
      name: "Instagram",
      url: "https://instagram.com/thefunbots",
      image: "/images/icons/instagram.svg",
    },
    {
      name: "GitHub",
      url: "https://github.com/thefunbots",
      image: "/images/icons/github.svg",
    },
    {
      name: "YouTube",
      url: "https://youtube.com/thefunbots",
      image: "/images/icons/youtube.svg",
    },
  ]

  const renderFooter = () => {
    return (
      <div className="bg-black">
        <div className="pattern-dots-md bg-transparent text-gray-800 h-7 mt-1"></div>

        <div className="container grid lg:px-0 lg:grid-cols-5 gap-4 px-4 py-8 lg:py-20 mx-auto">
          <div className="col-span-2">
            <div>
              <img
                className="h-7 w-auto sm:mb-8 md:mb-8"
                src="/images/logo-white.svg"
                alt=""
              />
            </div>
            <div className="my-8 lg:my-0">
              <span className="text-md flex">
                {map(socialNetworks, item => {
                  return (
                    <a
                      key={item.url}
                      href={item.url}
                      className="text-gray-500 mr-4 opacity-50 hover:opacity-100
                     transform hover:scale-110 transition duration-300 ease-in-out"
                      rel="noopener"
                    >
                      <img
                        src={item.image}
                        style={{ height: 24, width: "auto" }}
                        alt={item.name}
                      />
                    </a>
                  )
                })}
              </span>
            </div>
          </div>
          <div className="col-span-3">
            <div className="grid lg:grid-cols-3 gap-4">
              {map(footerSections, (items, section) => {
                return (
                  <div key={section}>
                    <h4 className="font-mono text-gray-500 pb-3 text-lg">
                      {section}
                    </h4>
                    <ul className="list-none">
                      {map(items, (url, title) => {
                        return (
                          <li key={url} className="text-md mb-2 text-md">
                            <a
                              href={url}
                              className="text-gray-100 hover:text-blue-600 hover:no-underline"
                            >
                              {title}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCredits = () => {
    return (
      <div className="bg-black py-6 px-10 mx-auto">
        <div className="lg:grid grid-cols-5 gap-4 container mx-auto">
          <div className="text-left text-sm text-gray-500 col-span-2">
            © 2020 thefunbots. All rights reserved
          </div>
          <div className="text-left text-gray-500 col-span-3 text-sm">
            Made with <span className="text-red-600">♥</span> in Barcelona
          </div>
        </div>
      </div>
    )
  }

  return (
    <footer>
      {/*<div className="pattern-diagonal-stripes-sm h-5 bg-gray-700 text-yellow-100"></div>*/}
      {renderFooter()}
      {renderCredits()}
    </footer>
  )
}
