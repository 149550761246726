import React from "react"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import SubscribeNewsletter from "../components/subscribe"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default props => (
  <div className="min-h-screen flex flex-col">
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      {/*<script*/}
      {/*  data-ad-client="ca-pub-3251327102100022"*/}
      {/*  async*/}
      {/*  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"*/}
      {/*></script>*/}
    </Helmet>
    <GatsbySeo
      titleTemplate="%s | thefunbots"
      canonical="https://thefunbots.com/"
      language="en"
      openGraph={{
        //url: "https://www.url.ie/a",
        title: "thefunbots",
        //description: "Open Graph Description",
        // images: [
        //   {
        //     url: "https://www.example.ie/og-image-01.jpg",
        //     width: 800,
        //     height: 600,
        //     alt: "Og Image Alt",
        //   },
        //   {
        //     url: "https://www.example.ie/og-image-02.jpg",
        //     width: 900,
        //     height: 800,
        //     alt: "Og Image Alt Second",
        //   },
        //   { url: "https://www.example.ie/og-image-03.jpg" },
        //   { url: "https://www.example.ie/og-image-04.jpg" },
        // ],
        site_name: "thefunbots",
      }}
      twitter={{
        site: "@thefunbots",
        cardType: "summary",
        creator: "@thefunbots",
      }}
      additionalMetaTags={[
        {
          property: "dc:creator",
          content: "Santi",
        },
        {
          name: "application-name",
          content: "thefunbots",
        },
      ]}
    />
    <Header />
    <div className="p-4 lg:p-12"></div>

    <div className="flex-grow">{props.children}</div>

    <SubscribeNewsletter />
    <Footer />
  </div>
)
