import React, { Component } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"
import {
  InstantSearch,
  Configure,
  Index,
  Highlight,
  connectAutoComplete,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"

let searchClient

if (process.env.NODE_ENV === `production`) {
  searchClient = algoliasearch("HMH3JGARBJ", "049d723e58d6d7cd73230bda1603ef21")
} else {
  searchClient = algoliasearch("03BRL9NQW4", "635bc3262b7387011fb8f41d7519dc6c")
}

const Search = () => (
  <InstantSearch searchClient={searchClient} indexName="apps">
    <Configure hitsPerPage={5} />
    <CustomAutocomplete />
    <Index indexName="apps" />
    <Index indexName="projects" />
    <Index indexName="blog-posts" />
  </InstantSearch>
)

class AutoComplete extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
  }

  state = {
    value: this.props.currentRefinement,
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
  }

  getSuggestionValue(hit) {
    if (!hit) return
    return hit && "name" in hit ? hit.name : ""
  }

  renderSuggestion(hit) {
    if (!hit) return
    const attribute = "name" in hit ? "name" : "title"
    return <Highlight attribute={attribute} hit={hit} tagName="mark" />
  }

  renderSectionTitle(section) {
    return section.index
  }

  getSectionSuggestions(section) {
    return section.hits
  }

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    console.log(suggestion)

    if ("url" in suggestion) {
      const internal = /^\/(?!\/)/.test(suggestion.url)
      if (internal) {
        navigate(suggestion.url)
      } else {
        document.location.href = suggestion.url
      }

      return
    }

    if ("path" in suggestion) {
      navigate(suggestion.path)
      return
    }
  }

  shouldRenderSuggestions(value) {
    return value && value.length && value.trim().length > 2
  }

  render() {
    const { hits } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: "Search...",
      onChange: this.onChange,
      value,
      spellCheck: 0,
      autoCorrect: 0,
    }

    return (
      <Autosuggest
        suggestions={hits}
        multiSection={true}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
        focusInputOnSuggestionClick={false}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    )
  }
}
const CustomAutocomplete = connectAutoComplete(AutoComplete)

export default Search
