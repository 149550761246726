import React, { useState } from "react"
import { map } from "lodash"
import { Link } from "gatsby"
//import Link from "gatsby-plugin-transition-link"
//import Link from "gatsby-plugin-transition-link/AniLink"

import classNames from "classnames"

import Search from "./search"

export default () => {
  const [menuOn, setMenuOn] = useState(false)

  const sections = {
    Apps: "/",
    //"Crafter Bots": "bots",
    "Blog & News": "blog",
    "Open Source": "open-source/projects",
    //"Open Source": "https://github.com/thefunbots",
  }

  const renderSiderBarItems = () => {
    return map(sections, (url, name) => {
      const partiallyActive = url !== "/"
      return (
        <Link
          key={url}
          to={url}
          className="no-underline transform block transition duration-300 ease-in-out inline-block hover:scale-110 font-mono ml-4 px-4 py-2 rounded-md text-sm font-bold leading-5 text-white hover:text-white hover:bg-gray-900 hover:no-underline focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out whitespace-no-wrap"
          activeClassName="bg-blue-700 focus:bg-blue-700 focus:text-white text-white no-underline text-white"
          partiallyActive={partiallyActive}
        >
          {name}
        </Link>
      )
    })
  }

  const renderMobileMenu = () => {
    return (
      <div
        className={classNames(
          "fixed top-0 inset-x-0 transition transform origin-top-right h-full bg-black",
          { hidden: !menuOn }
        )}
      >
        <div className="rounded-lg shadow-md">
          <div
            className="rounded-lg shadow-xs overflow-hidden"
            style={{
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
              backgroundColor: "rgba(0,0,0,.90)",
            }}
          >
            <div className="px-4 pt-2 flex items-center justify-between">
              <div>
                {/*<img*/}
                {/*  className="h-4 w-auto"*/}
                {/*  src="/images/logo-white-3.svg"*/}
                {/*  alt=""*/}
                {/*/>*/}
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  onClick={() => setMenuOn(false)}
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {map(sections, (url, name) => {
                return (
                  <Link
                    key={url}
                    to={url}
                    className="block px-3 py-4 rounded-md text-base font-medium text-gray-300 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  >
                    {name}
                  </Link>
                )
              })}
            </div>
            {/*<div>*/}
            {/*  <a*/}
            {/*    href="#"*/}
            {/*    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"*/}
            {/*  >*/}
            {/*    Log in*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    )
  }

  return (
    <nav
      className="fixed top-0 w-screen"
      style={{
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
        backgroundColor: "rgba(0,0,0,.85)",
        zIndex: 9999,
      }}
    >
      <div className="container mx-auto px-2 sm:px-6 lg:px-0 lg:py-4">
        <div className="relative flex items-center justify-between h-14 sm:h-16">
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <button
              onClick={() => setMenuOn(true)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-white hover:bg-blue-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
            >
              <svg
                className="block h-6 w-6å"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1NO flex items-center justify-center sm:items-stretch sm:justify-start lg:mr-12">
            <div className="flex-shrink-0">
              <Link to="/">
                <img
                  className="block lg:hidden h-6 w-auto mt-1 ml-2"
                  src="/images/logo-white-3.svg"
                  alt="thefunbots"
                  onClick={() => setMenuOn(!menuOn)}
                />
                <img
                  className="hidden lg:block transform block transition duration-300 ease-in-out hover:opacity-75"
                  style={{
                    height: 30,
                    marginTop: 1,
                  }}
                  src="/images/logo-white-3.svg"
                  alt="thefunbots"
                />
              </Link>
            </div>
            <div className="hidden md:block sm:ml-16 flex-grow">
              <div className="flex">
                {renderSiderBarItems()}
                {/*<a*/}
                {/*  href="#"*/}
                {/*  className="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
                {/*>*/}
                {/*  Dashboard*/}
                {/*</a>*/}
                {/*<a*/}
                {/*  href="#"*/}
                {/*  className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
                {/*>*/}
                {/*  Team*/}
                {/*</a>*/}
                {/*<a*/}
                {/*  href="#"*/}
                {/*  className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
                {/*>*/}
                {/*  Projects*/}
                {/*</a>*/}
                {/*<a*/}
                {/*  href="#"*/}
                {/*  className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
                {/*>*/}
                {/*  Calendar*/}
                {/*</a>*/}
              </div>
            </div>
          </div>

          <div className="absolutex inset-y-0 right-0 flex flex-grow items-right pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 hidden lg:flex">
            <Search />

            <a
              href="https://github.com/thefunbots"
              className="hover:opacity-75 ml-6"
              target="_blank"
              rel="noopener"
            >
              <img
                className="h-8 w-8"
                src="/images/icons/github.svg"
                alt="github"
              />
            </a>

            {/*<button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">*/}
            {/*  <svg*/}
            {/*    className="h-6 w-6"*/}
            {/*    stroke="currentColor"*/}
            {/*    fill="none"*/}
            {/*    viewBox="0 0 24 24"*/}
            {/*  >*/}
            {/*    <path*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*      strokeWidth="2"*/}
            {/*      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"*/}
            {/*    />*/}
            {/*  </svg>*/}
            {/*</button>*/}

            <div className="ml-3 relative">
              {/*<div>*/}
              {/*  <button className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out">*/}
              {/*    <img*/}
              {/*      className="h-8 w-8 rounded-full"*/}
              {/*      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
              {/*      alt=""*/}
              {/*    />*/}
              {/*  </button>*/}
              {/*</div>*/}

              {/*<div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">*/}
              {/*  <div className="py-1 rounded-md bg-white shadow-xs">*/}
              {/*    <a*/}
              {/*      href="#"*/}
              {/*      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"*/}
              {/*    >*/}
              {/*      Your Profile*/}
              {/*    </a>*/}
              {/*    <a*/}
              {/*      href="#"*/}
              {/*      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"*/}
              {/*    >*/}
              {/*      Settings*/}
              {/*    </a>*/}
              {/*    <a*/}
              {/*      href="#"*/}
              {/*      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"*/}
              {/*    >*/}
              {/*      Sign out*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>

      {renderMobileMenu()}

      {/*<div className="sm:hidden" class={}>*/}
      {/*  <div className="px-2 pt-2 pb-3">*/}
      {/*    <a*/}
      {/*      href="#"*/}
      {/*      className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
      {/*    >*/}
      {/*      Dashboard*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      href="#"*/}
      {/*      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
      {/*    >*/}
      {/*      Team*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      href="#"*/}
      {/*      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
      {/*    >*/}
      {/*      Projects*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      href="#"*/}
      {/*      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"*/}
      {/*    >*/}
      {/*      Calendar*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </nav>
  )
}
