import React, { useState } from "react"
import { map } from "lodash"
import { Link } from "gatsby"
import classNames from "classnames"

export default () => {
  const [sending, setSending] = useState(false)

  const sendForm = event => {}

  return (
    <div className="relative bg-teal-100 px-4 py-6 overflow-hidden sm:px-6 sm:py-8 lg:p-16 xl:p-22">
      <div className="container mx-auto lg:grid lg:grid-cols-2 lg:flex">
        <div className="lg:pr-12">
          <h2 className="text-2xl leading-8 tracking-tighter font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9">
            We've got more coming...
          </h2>
          <p className="mt-2 max-w-2xl text-base leading-6 text-gray-500">
            Want to hear from us when we have news? Sign up for our newsletter
            and we'll email you every time we release a new blog post about
            tech, app updates & open source projects.
          </p>
        </div>
        <div className="justify-end">
          <form
            x-data="signupForm()"
            className="mt-6"
            action="https://ck-proxy.adamwathan.now.sh/api/subscribers"
            method="POST"
          >
            <div className="sm:flex">
              <input
                x-model="email"
                type="email"
                required=""
                placeholder="Enter your email"
                className="block sm:max-w-xs w-full px-4 py-2 text-base leading-6 appearance-none border border-gray-300 shadow-none bg-white rounded-md text-black placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:text-black"
              />
              <button
                className="mt-4 relative sm:mt-0 sm:h-auto sm:ml-4 block w-full sm:w-auto border border-transparent px-6 py-2 text-base leading-6 font-semibold leading-snug bg-gray-900 text-white rounded-md shadow-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition ease-in-out duration-150 hover:bg-gray-600"
                classx="{ 'opacity-50 pointer-events-none': submitting, 'hover:bg-gray-600': !submitting }"
                disabled="submitting"
              >
                <span className={classNames({ "opacity-0": sending })}>
                  Subscribe
                </span>
                <span
                  className={classNames(
                    "absolute inset-0 flex items-center justify-center pointer-events-none opacity-0",
                    { "opacity-0": sending }
                  )}
                >
                  <svg className="h-8 w-8 spin" viewBox="0 0 24 24">
                    <path
                      className="text-gray-600"
                      fill="currentColor"
                      d="M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z"
                    ></path>
                    <path
                      className="text-gray-400"
                      fill="currentColor"
                      d="M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
